export enum MENU_CATEGORY_ENUM {
  HYPERCONNECT = "company",
  PRODUCT = "business",
  TECH = "tech",
  NEWSROOM = "news",
  CAREER = "career",
  CONTACT = "contact",
}

export type MenuItemsType = {
  label: string;
  link: string;
  exclude?: string;
  external?: boolean;
};

type MenuCategoryType = {
  [value in MENU_CATEGORY_ENUM]: {
    label: string;
    link: string;
    external?: boolean;
  };
};

export const MENU_CATEGORY: MenuCategoryType = {
  [MENU_CATEGORY_ENUM.HYPERCONNECT]: {
    label: "About",
    link: "/",
  },
  [MENU_CATEGORY_ENUM.PRODUCT]: {
    label: "Product",
    link: "/business",
  },
  [MENU_CATEGORY_ENUM.TECH]: {
    label: "Tech",
    link: "/tech",
  },
  [MENU_CATEGORY_ENUM.NEWSROOM]: {
    label: "Newsroom",
    link: "/news",
  },
  [MENU_CATEGORY_ENUM.CAREER]: {
    label: "Career",
    link: "https://career.hyperconnect.com/",
    external: true,
  },
  [MENU_CATEGORY_ENUM.CONTACT]: {
    label: "Contact",
    link: "/contact",
  },
};
export const MENU_ITEMS: {
  [key in MENU_CATEGORY_ENUM]: MenuItemsType[];
} = {
  [MENU_CATEGORY_ENUM.HYPERCONNECT]: [
    { label: "Hyperconnect", link: "/" },
    { label: "History", link: "/company/milestone" },
  ],
  [MENU_CATEGORY_ENUM.PRODUCT]: [{ label: "Azar", link: "/business" }],
  [MENU_CATEGORY_ENUM.TECH]: [
    { label: "AI", link: "/tech/aiml" },
    { label: "Data", link: "/tech/data" },
    { label: "WebRTC", link: "/tech/webrtc" },
    {
      label: "TechBlog",
      link: "https://hyperconnect.github.io/",
      external: true,
    },
  ],
  [MENU_CATEGORY_ENUM.NEWSROOM]: [
    { label: "Newsroom", link: "/news", exclude: "en" },
  ],
  [MENU_CATEGORY_ENUM.CAREER]: [],
  [MENU_CATEGORY_ENUM.CONTACT]: [],
};
