import { Link, useIntl } from "gatsby-plugin-intl";
import React, { FC, useCallback, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FacebookIcon from "@material-ui/icons/Facebook";
import Hidden from "@material-ui/core/Hidden";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { screenWidth } from "../../hooks/useCssMediaDevice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      zIndex: 1,
      backgroundColor: "#1c1c1c",
      color: "white",
      paddingTop: "30px",
    },
    upperSection: {
      display: "flex",

      [theme.breakpoints.up("xs")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.up("lg")]: {
        flexDirection: "row",
      },
    },
    bottomSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid rgba(233, 233, 233, 0.2)",
      paddingTop: "20px",
      paddingBottom: "20px",
      paddingLeft: "16px",
      paddingRight: "16px",
      marginTop: "50px",
      gap: "24px",

      [theme.breakpoints.up("xs")]: {
        paddingLeft: "24px",
        paddingRight: "24px",
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "80px",
        paddingRight: "80px",
      },
      [theme.breakpoints.down("xs")]: {
        alignItems: "flex-start",
      },
    },
    logoSection: {
      width: "100%",
      paddingLeft: "16px",

      [theme.breakpoints.up("xs")]: {
        paddingLeft: "24px",
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "80px",
        width: "25%",
        minWidth: "300px",
      },
    },
    logo: {
      marginBottom: 16,
      width: 150,
    },
    navigationList: {
      display: "flex",
      justifyContent: "space-between",
      margin: 0,
      listStyle: "none",

      [theme.breakpoints.up("xs")]: {
        flexDirection: "column",
        paddingLeft: "24px",
        marginTop: "30px",
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: "80px",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: 0,
        paddingLeft: 0,
        flexDirection: "row",
      },
    },
    copyright: {
      color: "#858585",
      fontSize: "12px",
      margin: 0,
    },
    logoImage: {
      marginBottom: "30px",
    },
    categoryItem: {
      display: "inline-block",

      [theme.breakpoints.up("xs")]: {
        marginBottom: "30px",

        "&:last-child": {
          marginBottom: 0,
        },
      },
      [theme.breakpoints.up("lg")]: {
        marginBottom: 0,
      },
    },
    footerHead: {
      color: "#ffffff",
      fontSize: "16px",
      fontFamily: ["Poppins", "system-ui", "sans-serif"].join(","),
      fontWeight: 700,
      marginBottom: "16px",
      textDecoration: "none",
      padding: "0px 90px 20px 0px",
    },
    subPageLinkGroupList: {
      listStyleType: "none",
      margin: "20px 0 0 0",

      "& > li": {
        marginBottom: "10px",

        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
    subPageLink: {
      fontSize: "14px",
      display: "block",
      color: "#858585",
      textDecoration: "none",
    },
    socialHead: {
      fontFamily: ["Poppins", "system-ui", "sans-serif"].join(","),
      fontWeight: 700,
      fontSize: "10px",
      color: "#858585",
      marginBottom: "12px",
    },
    socialLink: {
      textDecoration: "none",
      color: "#858585",
      marginRight: "12px",
    },
    popoverButton: {
      width: "135px",
      border: "1px solid rgba(233, 233, 233, 0.15)",
      color: "#858585",
      fontSize: "12px",
      borderRadius: 0,
    },
    popoverWrapper: {
      width: "135px",
      display: "flex",
      flexDirection: "column",
      border: "1px solid rgba(233, 233, 233, 0.15)",
      borderRadius: 0,
      padding: "12px 16px",
      backgroundColor: "#1c1c1c",
    },
    menuItem: {
      backgroundColor: "#1c1c1c",
      border: 0,

      "& > a": {
        textDecoration: "none",
        color: "#858585",
        fontSize: "17px",
      },
    },
    termsLink: {
      color: "#888888",
      textDecoration: "none",
      fontSize: "14px",
      cursor: "pointer",
    },
    bold: {
      fontWeight: 700,
    },
    bottomSectionRight: {
      display: "flex",
      alignItems: "center",
      gap: "24px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    termsLinkDivider: {
      color: "#888888",
      fontSize: "14px",
      padding: "0 8px",
    },
    // termsContainer: {
    //   marginRight: "24px",
    // },
  })
);

interface FooterProps {
  language: string;
}

const Footer: FC<FooterProps> = ({ language }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const termsPrefix = language === "ko" ? "ko" : "en";

  return (
    <footer className={classes.root}>
      <section className={classes.upperSection}>
        <div className={classes.logoSection}>
          <Link to="/">
            <img
              src="/img-hyperconnect-logo.svg"
              alt="logo"
              className={classes.logo}
            />
          </Link>
          <div>
            <a
              href="https://mtch.com/"
              target="_blank"
              rel="noreferrer"
              className={classes.socialLink}
            >
              <img src="/icons/Logo/imgMGWhite.svg" />
            </a>
          </div>
          <Typography className={classes.socialHead} component="p">
            Follow us
          </Typography>
          <a
            href="https://www.linkedin.com/company/hyperconnect"
            target="_blank"
            rel="noreferrer"
            className={classes.socialLink}
          >
            <img src="/icons/Logo/IcLinkedin.svg" />
          </a>
          <a
            href="https://www.instagram.com/hyperconnect"
            target="_blank"
            rel="noreferrer"
            className={classes.socialLink}
          >
            <img src="/icons/Logo/IcInstagram.svg" />
          </a>
          <a
            href="https://www.facebook.com/hpcnt"
            target="_blank"
            rel="noreferrer"
            className={classes.socialLink}
          >
            <img src="/icons/Logo/IcFacebook.svg" />
          </a>
        </div>
        <div>
          <ul className={classes.navigationList}>
            <li className={classes.categoryItem}>
              <Link to="/" className={classes.footerHead}>
                About
              </Link>
              <ul className={classes.subPageLinkGroupList}>
                <li>
                  <Link to="/company/milestone" className={classes.subPageLink}>
                    Our History
                  </Link>
                </li>
                {language === "ko" && (
                  <li>
                    <Link to="/news" className={classes.subPageLink}>
                      News
                    </Link>
                  </li>
                )}
              </ul>
            </li>

            <li className={classes.categoryItem}>
              <Link to="/business" className={classes.footerHead}>
                Product
              </Link>
              <ul className={classes.subPageLinkGroupList}>
                <li>
                  <Link to="/business#azar" className={classes.subPageLink}>
                    Azar
                  </Link>
                </li>
              </ul>
            </li>

            <li className={classes.categoryItem}>
              <Link to="/tech" className={classes.footerHead}>
                Tech
              </Link>
              <ul className={classes.subPageLinkGroupList}>
                <li>
                  <Link to="/tech/aiml" className={classes.subPageLink}>
                    AI
                  </Link>
                </li>
                <li>
                  <Link to="/tech/data" className={classes.subPageLink}>
                    Data
                  </Link>
                </li>
                <li>
                  <Link to="/tech/webrtc" className={classes.subPageLink}>
                    WebRTC
                  </Link>
                </li>
                <li>
                  <a
                    href="https://hyperconnect.github.io/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.subPageLink}
                  >
                    Tech Blog
                  </a>
                </li>
              </ul>
            </li>

            <li className={classes.categoryItem}>
              <a
                href="https://career.hyperconnect.com/"
                target="_blank"
                rel="noreferrer"
                className={classes.footerHead}
              >
                Career
              </a>
            </li>

            <li className={classes.categoryItem}>
              <Link to="/contact" className={classes.footerHead}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </section>

      <section className={clsx(classes.bottomSection)}>
        <Hidden smDown>
          <Typography component="p" className={classes.copyright}>
            Copyright © {new Date().getFullYear()}, HYPERCONNECT LLC. All rights
            reserved
          </Typography>
        </Hidden>
        <Hidden mdUp>
          <Typography component="p" className={classes.copyright}>
            © {new Date().getFullYear()} HYPERCONNECT LLC. <br />
            All rights reserved
          </Typography>
        </Hidden>
        <div className={classes.bottomSectionRight}>
          <div>
            <Link to="/ir" className={classes.termsLink}>
              Notice
            </Link>
            <span className={classes.termsLinkDivider}>|</span>
            <Link
              to="/company/intellectualProperty"
              className={classes.termsLink}
            >
              IP
            </Link>
            <span className={classes.termsLinkDivider}>|</span>
            <Link to="/company/CodeOfEthics" className={classes.termsLink}>
              Code of Ethics
            </Link>
            <span className={classes.termsLinkDivider}>|</span>
            <a
              href={`/terms/${termsPrefix}/privacy-policy.html`}
              className={classes.termsLink}
            >
              {intl.formatMessage({ id: "privacy_policy" })}
            </a>
            <span className={classes.termsLinkDivider}>|</span>
            <a
              href={`/terms/${termsPrefix}/cookie-policy.html`}
              className={classes.termsLink}
            >
              {intl.formatMessage({ id: "cookie_policy" })}
            </a>
            <span className={classes.termsLinkDivider}>|</span>
            <a
              onClick={() => window.OneTrust?.ToggleInfoDisplay()}
              className={classes.termsLink}
            >
              {intl.formatMessage({ id: "cookie_settings" })}
            </a>
          </div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.popoverButton}
            endIcon={
              Boolean(anchorEl) ? <ExpandMoreIcon /> : <ExpandLessIcon />
            }
          >
            Related site
          </Button>
          <Popover
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className={classes.popoverWrapper}>
              <button className={classes.menuItem}>
                <a
                  href={intl.formatMessage({ id: "azar_website" })}
                  target="_blank"
                  rel="noreferrer"
                >
                  Azar Live
                </a>
              </button>
            </div>
          </Popover>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
